// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-all-sccp-tsx": () => import("./../../../src/pages/all-sccp.tsx" /* webpackChunkName: "component---src-pages-all-sccp-tsx" */),
  "component---src-pages-all-sip-tsx": () => import("./../../../src/pages/all-sip.tsx" /* webpackChunkName: "component---src-pages-all-sip-tsx" */),
  "component---src-pages-all-stp-tsx": () => import("./../../../src/pages/all-stp.tsx" /* webpackChunkName: "component---src-pages-all-stp-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-sccp-sccp-markdown-remark-frontmatter-sccp-tsx": () => import("./../../../src/pages/sccp/sccp-{MarkdownRemark.frontmatter__sccp}.tsx" /* webpackChunkName: "component---src-pages-sccp-sccp-markdown-remark-frontmatter-sccp-tsx" */),
  "component---src-pages-sips-sip-markdown-remark-frontmatter-sip-tsx": () => import("./../../../src/pages/sips/sip-{MarkdownRemark.frontmatter__sip}.tsx" /* webpackChunkName: "component---src-pages-sips-sip-markdown-remark-frontmatter-sip-tsx" */),
  "component---src-pages-stps-stp-markdown-remark-frontmatter-stp-tsx": () => import("./../../../src/pages/stps/stp-{MarkdownRemark.frontmatter__stp}.tsx" /* webpackChunkName: "component---src-pages-stps-stp-markdown-remark-frontmatter-stp-tsx" */)
}

